.trail-detail {
  height:100%;
  min-height:calc(100vh - 61px)
}
.trail-detail__map {
  height: 310px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.trail-detail__content {
  padding: 0 40px;
  color: white;
}

.trail-detail__header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.trail-detail__image {
  width: 140px;
  height: auto;
  margin: -75px 20px 0 0;
}

.trail-detail__image img {
  max-width:100%;
  border-radius:10px;
  border-style: solid;
  border-width: 4px;
}

.trail-detail__header > h3 {
  margin: 0;
  padding: 0;
}

.trail-detail__content > hr {
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.trail-detail__details {
  display: flex;
  justify-content: space-between;
}

.trail-detail__content > p {
  font-size: 14px;
  line-height: 20px;
}

.trail-detail__details strong {
  font-weight:bold;
}

.trail-detail__content > a {
  margin-bottom: 20px;
}

@media only screen and (min-width:759px) {
  .select-trail__select {
    height: 250px;
  }
  .select-trail__map,
  .trail-detail__map{
      height:500px;
      max-height:500px;
  }

  .trail-detail__content {
    padding:0 78px;
    height:calc(100vh - 500px);
  }
  .trail-detail__image {
    width:245px;
    height:auto;
  }

  .trail-detail__content > p {
    font-size: 18px;
    line-height: 25px;
    margin-bottom:2em;
  }
}

@media only screen
and (orientation : landscape) {
  .trail-detail__content {
    height:auto;
  }
}
