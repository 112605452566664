.trail-result {
  width: 100vw;
  height: calc(100vh - 61px);
}

.trail-result__wrapper {
  width: 100vw;
  padding-bottom:20px;
}

.trail-result__centered {
  display: flex;
  justify-content: center;
}

.trail-result__avatar {
  margin-top: 10%;
  border-width: 4px;
  border-style: solid;
}

.trail-result__content {
  color: white;
}
.trail-result__content a.btn {
  margin-top:20px;
}

.trail-result__content h1 {
  margin: 30px 0 0;
}

.trail-result__content hr {
  margin: 10px 0 20px;
  border: 4px solid rgba(255, 255, 255, 0.4);
}

.trail-result__content h4 {
  margin: 0 0 5px;
}

.trail-result__content p {
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.trail-result__points {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px;
  margin: 20px 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.trail-result__points h5 {
  margin: 0;
}

.trail-result__points h4:first-child {
  text-transform: uppercase;
}

.trail-result__content button {
  margin: 0 0 20px
}
.trail-result__content .trail-result__lowercase {
  text-transform: initial;
}
