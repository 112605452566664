.overlay--badges,
.overlay--badges-list {
  background-color: $badgesBackgroundBlue;
}
.overlay--badges .actions {
  height:24px;
}
.overlay--badges .content {
  margin-top:120px;
  height:auto;
}
.overlay--badges hr {
  border-top:7px solid $badgesBackgroundBlue;
}
