.multiple-choice-question__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 110px;
  padding: 0 45px;
  background: #bb490f;
}

.multiple-choice-question__avatar {
  width: 145px;
  height: 125px;
  margin-top: 20px;
  border-radius: 10px;
  border: 4px solid #bb490f;
  overflow:hidden;
}

.multiple-choice-question__avatar .img-fluid {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}

.multiple-choice-question__points {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  padding: 0 0 0 20px;
}

.multiple-choice-question__points > hr {
  width: 100%;
  border-top: 1px solid white;
}

.multiple-choice-question__points > p {
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-weight: 700;
}

.multiple-choice-question__information {
  padding: 0 45px;
  margin-top: 60px;
}

.multiple-choice-question__information > h2 {
  padding-left: 10px;
  margin: 0;
}

.multiple-choice-question__information > hr {
  margin: 10px 0 20px;
  border: 4px solid #ff5600;
  opacity: 0.3;
}

.multiple-choice-question__information-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px 10px;
}

.multiple-choice-question__question {
  padding: 10px;
  background: rgba(255, 86, 0, 0.3);
  border-radius: 5px;
}

.multiple-choice-question__answers {
  padding: 0 45px;
}

.multiple-choice-question__answer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-right:50px;
  border: 2px solid rgba(255, 86, 0, 0.3);
  border-radius: 5px;
  margin-bottom: 10px;
  position:relative;
  min-height:55px;
}

.multiple-choice-question__answer:last-child {
  margin-bottom: 0;
}

.multiple-choice-question__correct,
.multiple-choice-question__incorrect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: rgba(255, 86, 0, 0.3);
  border-radius: 50%;
  position:absolute;
  right:10px;
  bottom:calc(50% - 17px);
}

.multiple-choice-question__answer--yes {
  background: #bb490f;
  color: white;
}
.multiple-choice-question__answer--no {
  color:white;
}
.multiple-choice-question__answer--no:after {
  position:absolute;
  top:0;
  left:0;
  content:" ";
  background-color:#000;
  opacity:0.3;
  width:100%;
  height:100%;
  pointer-events: none;
  z-index: -1;
}

.multiple-choice-question__answer--yes > .multiple-choice-question__correct,
.multiple-choice-question__answer--no > .multiple-choice-question__incorrect {
  background: white;
}

.multiple-choice-question__footer {
  padding: 0 45px;
  margin: 20px 0;
}
.multiple-choice-question__footer div {
  margin-bottom:20px;
}
.multiple-choice-question__footer button + button {
  margin-left:20px;
}
.multiple-choice-question__footer.answer-selected .btn-secondary {
  background:var(--yellow)!important;
  border-color:var(--yellow)!important;
  color: $black!important;
  box-shadow:none;
}
.multiple-choice-question__footer.answer-selected .btn:focus,
.multiple-choice-question__footer.answer-selected .btn:active,
.multiple-choice-question__footer.answer-selected .btn-secondary:focus,
.multiple-choice-question__footer.answer-selected .btn-secondary:active {
  cursor: pointer;
  background:$badgesBackgroundBlue!important;
  border-color:$badgesBackgroundBlue!important;
}

.multiple-choice-question__answers.lion .multiple-choice-question__answer--selected {
  background:$lionSecondaryTint;
}

.multiple-choice-question__answers.fox .multiple-choice-question__answer--selected {
  background:$foxSecondaryTint;
}

.multiple-choice-question__answers.dormouse .multiple-choice-question__answer--selected {
  background:$dormouseSecondaryTint;
}

@media only screen and (max-width:759px) {
  .multiple-choice-question__footer button + button {
    margin-left:0;
  }
}

@media only screen and (max-width:480px) {
  .multiple-choice-question__information,
  .multiple-choice-question__answers,
  .multiple-choice-question__footer {
    padding: 18px;
  }
}

@media only screen and (max-width:320px) {
  .multiple-choice-question__avatar {
    height: 115px;
  }
}
