.overlay--badges-list .content {
  margin-top:0;
  margin-left:-24px;
  margin-right:-24px;
  border-radius:0;
  height:auto;
  padding-bottom:20px;
}
.overlay--badges-list .overlay__image svg {
  width:127px;
  height:127px;
}

.overlay--badges-list .single-outer-wrapper .badge-item__main-image {
  width:190px;
  height:190px;
  overflow:hidden;
}
.overlay--badges-list .single-outer-wrapper .badge-item__main-image img {
  width:100%;
}
.overlay--badges-list .scroll-menu-arrow--disabled {
  opacity: 0;
}
.badge-item {
  height:100%;
  width:100%;
  max-width:240px;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.overlay--badges-list .overlay__image {
  margin-top:-64px;
  margin-bottom:8px;
}
.overlay--badges-list .menu-wrapper--inner {
  height:100%;
}
.overlay--badges-list .badges-introduction__image {
  pointer-events: none;
}

.overlay--badges-list .content hr {
  margin: 0 0 20px;
  border: 4px solid $badgesBackgroundBlue;
  height:0;
}

.badge-item__main-image {
  width: 250px;
  height: 250px;
  border-radius: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0 auto 72px;
  overflow:hidden;
}
.badge-item__main-image:before {
  content:"";
  border:10px solid red;
  position: absolute;
  z-index: 0;
  top: 9px;
  left: 9px;
  right: 9px;
  bottom: 9px;
  border-radius:250px;
}
.badge-item__main-image:after {
  content:"";
  border:10px solid blue;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius:250px;
}
.game-badge__image-wrapper {
  margin:0 auto;
  text-align:center;
}
.game-badge {
  display:flex;
  flex-direction:column;
}
.game-badge__badges {
  justify-content: center;
}
.game-badge__badges p {
  color:$black;
  font-size:14px;
}

.game-badge__individual-badge {
  width:70px;
  height:70px;
  border-radius:4px;
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-right:7px;
}
.game-badge__individual-badge > div {
  border-radius:50px;
  width:57px;
  height:57px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.badge-icon svg { opacity:0.3 }
.achieved .badge-icon svg { opacity:1; }

.overlay--badges-list .single-menu-wrapper {
  position:relative;
}
.overlay--badges-list .single-outer-wrapper {
  width:240px;
  max-width:100%;
  padding:0 0px;
}
.overlay--badges-list .single-outer-wrapper .menu-item-wrapper {
  width:100%;
  max-width:240px;
}

.overlay--badges-list .single-menu-wrapper .scroll-menu-arrow {
  position:absolute;
}
.overlay .game-badge__image-wrapper.col {
  padding:0;
}
.bronze.achieved {
  background:$bronzeBadgeColour;
}
.silver.achieved {
  background:$silverBadgeColour;
}
.gold.achieved {
  background:$goldBadgeColour;
}

.badge-icon.bronze svg {
  width:20px;
}
.badge-icon.silver svg {
  width:25px;
}
.badge-icon.gold svg {
  width:30px;
}
/*===== Overlay Single Badge Styling ====*/
.overlay--awarded-badge__content {
  padding:0 53px;
}
.overlay--awarded-badge__content .badge-item {
  margin:0 auto;
}
.overlay .overlay--awarded-badge__content .col {
  padding:0;
}
/** Colours **/
.lion .badge-item__main-image:before {
  border:10px solid $lionPrimary;
}
.lion .badge-item__main-image:after  {
  border:10px solid $lionSecondary;
}
.lion .game-badge__individual-badge {
  border:2px solid $lionSecondaryTint;
}
.lion .game-badge__individual-badge.achieved > div {
  background:$lionSecondaryTint;
}
.lion .game-badge__individual-badge > div {
  background:$lionSecondaryTint;
}
.lion .game-badge__individual-badge.achieved > div {
  background:$white;
  border:0;
}
.eye_spy .badge-item__main-image:before  {
  border:10px solid $lionSecondary;
}
.eye_spy .badge-item__main-image:after  {
  border:10px solid $lionSecondary;
}
.eye_spy .game-badge__individual-badge {
  border:2px solid $lionSecondaryTint;
}
.eye_spy .game-badge__individual-badge > div {
  background:$lionSecondaryTint;
}
.eye_spy .game-badge__individual-badge.achieved > div {
  background:$white;
  border:0;
}
.dormouse .badge-item__main-image:before  {
  border:10px solid $dormousePrimary;
}
.dormouse .badge-item__main-image:after  {
  border:10px solid $dormouseSecondary;
}
.dormouse .game-badge__individual-badge {
  border:2px solid $dormouseSecondaryTint;
}
.dormouse .game-badge__individual-badge > div {
  background:$dormouseSecondaryTint;
}
.dormouse .game-badge__individual-badge.achieved > div {
  background:$white;
  border:0;
}
.fox .badge-item__main-image:before  {
  border:10px solid $foxPrimary;
}
.fox .badge-item__main-image:after  {
  border:10px solid $foxSecondary;
}
.fox .game-badge__individual-badge {
  border:2px solid $foxSecondaryTint;
}
.fox .game-badge__individual-badge > div {
  background:$foxSecondaryTint;
}
.fox .game-badge__individual-badge.achieved > div {
  background:$white;
  border:0;
}

