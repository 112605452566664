.overlay--eye-spy,
.overlay--eye-spies {
  background-color: $badgesBackgroundBlue;
  padding: 24px 24px 0 24px !important;
}

.overlay--eye-spy  hr,
.overlay--eye-spies hr {
  border-top:7px solid $badgesBackgroundBlue;
}

.overlay--eye-spies .content {
  border-radius: 0;
}

.overlay--eye-spies .row-content {
  margin-left:-24px;
  margin-right:-24px;
}
.overlay--eye-spies .content {
  margin-top:0;
}

.eye-spy-introduction.overlay--eye-spy .actions {
  height:24px;
}
.eye-spy-introduction.overlay--eye-spy .content {
  margin-top:120px;
  height:auto;
}
