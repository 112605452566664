.overlay {
  height:100%;
}
.overlay .actions {
  height:61px;
}
.overlay.container {
  padding:24px;
  overflow:scroll;
}
.overlay .content {
  background-color:$white;
  border-radius: 0 0 50px 0;
  margin-left:0;
  margin-right:0;
  height:80%;
  margin-top:80px;
}
.overlay .content .col {
  padding:20px;
}
.overlay hr {
  opacity:0.4;
  height:1px;
  margin-top:0;
}

.icon-close svg {
  width:24px;
  height:24px;
}
.icon-close svg path{
  fill:$white;
}

.overlay__image {
  margin:-100px auto 26px;
  text-align: center;
}
.overlay__image svg {
  width:155px;
  height:155px;
}
.overlay__image-small {
  margin: -65px auto 26px;
  text-align: center;
  pointer-events: none;
}

.overlay__image-small svg {
  width:125px;
  height:125px;
}
.overlay .body--copy {
  margin-top:20px;
}
.overlay__footer {
  padding:0;
}
.overlay__footer button {
  position: relative;
  display: block;
}
