$theme-colors: (
  "primary": #fce73f,
  "yellow": #fce73f,
  "blue": #4f78eb,
);

@import '~bootstrap/scss/bootstrap.scss';

// NewRailAlphabet Font
@font-face {
  font-family: NewRailAlphabet;
  src: url('../assets/fonts/NewRailAlphabet-Bold.woff') format('woff'),
       url('../assets/fonts/NewRailAlphabet-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: NewRailAlphabet;
  src: url('../assets/fonts/NewRailAlphabet-Light.woff') format('woff'),
       url('../assets/fonts/NewRailAlphabet-Light.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: NewRailAlphabet;
  src: url('../assets/fonts/NewRailAlphabet-White.woff') format('woff'),
       url('../assets/fonts/NewRailAlphabet-White.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: NewRailAlphabet;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: NewRailAlphabet;
  font-weight: 700;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

h7 {
  font-size: 16px;
}
.header--one {
  font-size: 40px;
  line-height: 1;
}
.header--two {
  font-size: 30px;
  line-height: 1.5;
}
.header--three {
  font-size: 26px;
  line-height: 1.23;
}
.header--four {
  font-size: 24px;
}
.header--five {
  font-size: 20px;
  line-height: 1.4;
}
.header--six {
  font-size: 18px;
}
.body--copy {
  font-size:14px;
  color:$bodyCharcoalCopy;
}
p {
  font-family: NewRailAlphabet;
  font-weight: 300;
}
.btn {
  text-transform: uppercase;
}
.btn-primary,
.btn-secondary {
  padding: 10px 40px 5px;
  border: 2px solid var(--yellow);
  text-transform: uppercase;
  font-family: NewRailAlphabet;
  font-weight: 700;
  color: #171a26;
  font-size: 15px;
  outline: none;
}
.btn-secondary {
  background:  $badgesBackgroundBlue;
  border-color:  $badgesBackgroundBlue;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background:  $badgesBackgroundBlue!important;
  border-color:  $badgesBackgroundBlue!important;
  box-shadow:none!important;
}
.btn-primary:hover {
  background: var(--blue);
  border-color: var(--blue);

}
.z {
  padding: 10px 40px 5px;
  border: 2px solid white;
  text-transform: uppercase;
  font-family: NewRailAlphabet;
  font-weight: 700;
  color: white;
  font-size: 15px;
  outline: none;
}

.btn-outline-secondary:hover {
  background: var(--blue);
  border-color: var(--blue);
}

.btn-outline-secondary__light-background {
  background: $white;
  border-color: $black;
  color:$black;
  text-transform: uppercase;
  padding: 10px 40px 5px;
}
.btn-outline-secondary__dark-background {
  border-color: $white;
  color:$white;
  padding: 10px 40px 5px;
}
.btn-previous {
  padding:10px 30px 5px;
  margin-right:10px;
}
.navbar {
  padding: 15px 20px;
}

.container {
  padding-right: 45px;
  padding-left: 45px;
}

.btn-toolbar>*+* {
  margin-left:1em;
}

@media only screen and (min-width:578px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width:759px) {
  .btn-primary,
  .btn {
    font-size:21px;
  }
  p {
    font-size:18px;
  }
  .body--copy {
    font-size:21px;
  }
  .container {
    max-width: 100%;
  }
}


@media only screen and (max-width: 352px) {
  //.dormouse .img-fluid {
  //  max-width: 100%;
  //  height: 100%;
  //}
  //.fox .img-fluid {
  //  max-width: 100%;
  //  height: 100%;
  //}
  //
  //.lion img{
  //  width: 115px;
  //}


}

