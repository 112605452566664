.navbar {
  padding:13px 20px;
}
.logo {
  max-width:63px;
  height:35px;
}
.navigation {
  background-color: black;
  height:61px;
}

.navigation__link {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
}

.navigation .nav-item {
  line-height:1;
}

.back-button {
  font-size:26px;
}
