/** TRAIL COLOURS **/
.select-trail.lion,
.trail-detail.lion{
  background:$lionPrimary;
}
.menu-item.active.lion {
  border: 4px solid $lionPrimary;
  box-shadow: 1px 1px 10px 2px white;
}
.select-trail__content.lion {
  background-color:$lionPrimary;
}
.select-trail.fox,
.trail-detail.fox {
  background:$foxPrimary;
}
.menu-item.active.fox {
  border: 4px solid $foxPrimary;
  box-shadow: 1px 1px 10px 2px white;
}
.select-trail__content.fox {
  background-color:$foxPrimary;
}
.select-trail.dormouse,
.trail-detail.dormouse {
  background:$dormousePrimary;
}
.menu-item.active.dormouse {
  border: 4px solid $dormousePrimary;
  box-shadow: 1px 1px 10px 2px white;
}

.select-trail__content.dormouse {
  background-color: $dormousePrimary;
}
