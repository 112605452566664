.splash-screen {
  display: flex;
  height:calc(100vh - 50px);
  padding-top:40px;
  align-items: flex-end;
  background-color: black;
  background-image: url('../assets/images/fox-full.jpg');
  background-position-x: 26%;
  background-position-y: -106px;
  background-repeat: no-repeat;
  background-size: cover;
}
.splash-screen:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent, black 54%);
  width: 100%;
  height: 35%;
}

.splash-screen__content {
  color: white;
  padding: 0 30px 30px;
  z-index: 1;
}

.splash-screen__content > h6 {
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.splash-screen__content > h1 {
  line-height: 40px;
  letter-spacing: -0.32px;
}

.splash-screen__content > h5 {
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.splash-screen__content > hr {
  border: 2px solid white;
  margin: 15px 0;
}

.splash-screen__content  p {
  margin-bottom: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color:$white;
}

.splash-screen a.btn-primary {
  padding:12px 40px 10px 34px;
  line-height: normal;
}
