.challenge-question {
  width: 100%;
  height: auto;
  min-height:100vh;

  background-color: #bb490f;
}

.challenge-question__header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding-top: 30px;
  background-color: white;
}

.challenge-question__avatar {
  width: 180px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 4px solid #bb490f;
}

.challenge-question__content {
  padding: 0 40px;
  padding-bottom:20px;
  margin-top: 110px;
  color: white;
}
.challenge-question__content button + button {
  margin-left:20px;
}

.challenge-question__content > h1 {
  margin-bottom: 10px;
}

.challenge-question__content > p {
  margin-bottom: 20px;
  font-size: 14px;
}

.challenge-question__top-hr {
  margin: 0 0 20px 0;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.challenge-question__bottom-hr {
  margin: 0 0 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.challenge-question__completed {
  margin-bottom: 30px;
}

.challenge-question__points {
  margin-bottom: 40px;
}

.challenge-question__points > p {
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.challenge-question__points-block {
  padding: 15px 15px 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.challenge-question__points-block > h3 {
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 32px;
}

@media only screen and (max-width:759px) {
  .challenge-question__content button + button {
    margin-left:0;
  }
}
