.select-trail {
  height: calc(100vh - 61px);
}
.select-trail__select {
  background-color: var(--blue);
  color: white;
}

.select-trail__select .content > h4 {
  padding: 14px 40px 0;
  margin-bottom: 10px;
}

.select-trail__select .content > p {
  padding: 0 40px;
  margin: 0 0 10px 0;
  font-size:14px;
}

.menu-wrapper--inner {
  height: 130px;
  display: flex;
  align-items: center;
}

.menu-item-wrapper {
  outline: none;
}

.menu-item {
  width: 115px;
  height: 100px;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  margin: 0 7px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow:hidden;
}

.menu-item .img-fluid {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}

.select-trail__map {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.select-trail__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #bb490f;
  padding: 20px 40px;
  color: white;
}

.select-trail__info {
  display: flex;
  align-items: center;
}

.select-trail__info > p {
  margin: 0;
  font-size: 15px;
}

.select-trail__info > a {
  color: white;
  margin-left: 10px;
  padding: 0;

  &:active, &:hover {
    color: white;
  }
}

@media only screen and (max-width:759px) {
  .select-trail {
    height: auto;
  }
}


@media only screen
and (orientation : landscape) {
  .select-trail {
    height:auto;
  }
  .select-trail__select .horizontal-menu {
    width:390px;
    margin:0 auto;
  }
  .select-trail__map {
    height:auto!important;
    max-height:none!important;
    padding-bottom:20px;
  }
  .select-trail__map img {
    width:100%;
    max-width:800px;
    margin:0 auto;
  }
  .select-trail__select .content,
  .select-trail__content .content,
  .select-trail__map .content {
    max-width:800px;
    margin:0 auto;
  }
}
@media only screen and (min-width:768px)
and (orientation : portrait) {
  .select-trail__select .horizontal-menu {
    width:390px;
    margin:0 auto;
  }
}
