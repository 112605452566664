.trail-introduction {
  width: 100vw;
  height: calc(100vh - 61px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.trail-introduction__wrapper {
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 72px);
}

.trail-introduction__content {
  padding: 20px;
  margin-bottom: 45px;
  color: white;
  border-radius: 0 0 65px 0;
  background-color: #7f7f7f;
}

.trail-introduction__content > h1 {
  margin: 0;
}

.trail-introduction__content > hr {
  margin: 10px 0 20px;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.trail-introduction__content > p {
  margin: 20px 0;
  font-size: 14px;
  line-height: 20px;
}


@media only screen
and (orientation : landscape) {
  .trail-introduction {
    background-position:center top;
  }
}


