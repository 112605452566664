.next-location {
  width: 100vw;
  height: calc(100vh - 72px);
  background: linear-gradient(var(--blue) 32%, #fff 0%, white 32%);
}

.next-location__centered {
  display: flex;
  justify-content: center;
}

.next-location__avatar {
  margin-top: 10%;
  border-width: 4px;
  border-style: solid;
  border-color: var(--blue);
}

.next-location__content h1 {
  margin: 30px 0 0;
}

.next-location__content hr {
  margin: 10px 0 20px;
  border: 4px solid rgba(79, 120, 235, 0.4);
}

.next-location__content h4 {
  margin: 0 0 5px;
}

.next-location__content p {
  margin: 0 0 30px;
  font-weight: 400;
  font-size: 14px;
}
.next-location .btn + button {
  margin-left:20px;
}

@media only screen and (max-width:759px) {
  .next-location__content .btn:hover {
    background: var(--yellow);
    border: var(--yellow);
  }
}

@media only screen and (max-width:480px) {
  .next-location__content .btn-previous {
    padding-left:20px;
    padding-right:20px;
  }
  .next-location .container{
    padding:0 25px;
  }

  .next-location .btn + button {
    margin-left:10px;
  }
}
