.eye-spy-task {
  padding: 0 0 37px 0;
  width:200px;
  margin:auto;
}
.btn-spotted {
  background-color: $badgesBackgroundBlue !important;
  border-color: $badgesBackgroundBlue !important;
  color: #ffffff !important;
  font-family: NewRailAlphabet;
  font-size: 15px;
  padding:0 10px !important;
  width: 149px;
  height: 45px;
}

.btn-light {
  background-color: $white !important;
}

.btn-spotted span, 
.btn-i-spy span {
  position: relative;
  top:2px;
}

.oval {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: $white;
  border-radius: 50%;
}

.blank_oval {
  @extend .oval;
  opacity: 0.2;
  background-color: $badgesBackgroundBlue;
}

.btn-i-spy {
  color: $black;
  font-size: 13px;
  font-weight: 300;
  width: 149px;
  height: 45px;
  opacity: 0.3;
  border-radius: 4px;
  border: solid 2px $badgesBackgroundBlue !important;
  padding:0 10px !important;
}

.btn-i-spy:focus, 
.btn-spotted:focus {
  background-color: $badgesBackgroundBlue !important;
  box-shadow: none !important;
}

.btn-i-spy:hover {
  background-color: $badgesBackgroundBlue;
  opacity: 0.65;
}

.i-spy-image {
  width: 100%;
  height:185px;
  border-radius: 9px;
  margin-bottom:21px;
}

.eye-spy-task p {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom:25px;
}

.single-outer-wrapper {
  max-width:200px;
  overflow: hidden;
  user-select: none;
}

.single-outer-wrapper {
  height: auto;
  display: flex;
  align-items: center;
}

.single-menu-wrapper {
  justify-content:center;
}

.arrow-class {
  position: relative;
  top: 95px;
  width: 21px;
  height: 35px;
}
.arrow-right,
.arrow-left {
  position:absolute;
  top:-180px;
}
.arrow-right--eye-spy,
.arrow-left--eye-spy {
  top:13px;
}
.eye-spy-listings__body-content {
  margin-bottom:20px;
  margin-left:10px;
}
.slick-next, .slick-prev {
  top:58%;
  z-index: 2;
}
.slick-next {
  right:25px;
}
.slick-prev{
  left:25px;
}

@media only screen and (max-width:759px) {
  .slick-list {
    margin: 0 -50px;
  }
}
  @media only screen and (max-width:400px) {
    .slick-next, .slick-prev {
      top:55%;
    }
    .slick-next {
      right:0;
    }
    .slick-prev{
      left:0;
    }
}

