.overlay--warning {
  background-color: $badgesBackgroundBlue;
}
.overlay--warning .actions {
  height:24px;
}
.overlay--warning .content {
  margin-top:120px;
  height:auto;
}
.overlay--warning  hr {
  border-top:7px solid $badgesBackgroundBlue;
}
