$white: #FFF;
$badgesBackgroundBlue: #4f78eb;
$black: #171a26;


$lionPrimary: #713e9c;
$lionSecondary: #904bcb;
$lionSecondaryTint: rgba(144, 75, 203, 0.3);
$lionBlackTing: rgba(0, 0, 0, 0.3);

$dormousePrimary: #9d2665;
$dormouseSecondary: #ff77bf;
$dormouseSecondaryTint:rgba(255, 119, 191, 0.3);
$dormouseBlackTing: rgba(0, 0, 0, 0.3);

$foxPrimary: #bb490f;
$foxSecondary: #fa732e;
$foxSecondaryTint: rgba(249, 98, 21, 0.3);
$foxBlackTing: rgba(0, 0, 0, 0.3);

$bronzeBadgeColour:#bb490f;
$silverBadgeColour:#b0b0b0;
$goldBadgeColour:#b27e23;

$bodyCharcoalCopy:#171a26;
