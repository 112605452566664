.fun-fact-overlay {
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  position:fixed;
  top:0;
  max-width:100%;
}

.fun-fact-overlay.container {
  padding: 0 20px 0;
  overflow:scroll;
}

.fun-fact-overlay__space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.fun-fact-overlay__navigation {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.fun-fact-overlay__content {
  height: 100%;
  margin: 75px 0 20px;
  padding: 0 25px 25px;
  background-color: white;
  border-radius: 0 0 50px 0;
}

.fun-fact-overlay__icon {
  width: 155px;
  height: 155px;
  margin: -75px auto 24px;
  padding-bottom: 20px;
  background-color: #7b9ff9;
  border-radius: 50%;
}

.fun-fact-overlay__content > h1 {
  margin: 0;
  line-height: 45px;
}

.fun-fact-overlay__content > hr {
  margin: 10px 0 20px;
  border: 4px solid rgba(79, 120, 235, 0.4);
}

.fun-fact-overlay__content > h4 {
  margin: 0 0 10px;
  line-height: 28px;
}

.fun-fact-overlay__content > p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
